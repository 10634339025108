<template>
  <v-main>
    <div class="auth-container">
      <!-- Left Side (Image) -->
      <div class="auth-left">
        <img class="auth-image" :src="getImgSrc" alt="Auth Background" />
      </div>

      <!-- Right Side (Login Form & Content) -->
      <div class="auth-right">
        <div class="auth-content">
          <img class="logo" src="@/assets/Auth/wizfirLogo.svg" alt="Logo" />
          <router-view></router-view>
        </div>
        <img
          class="basket-shadow"
          src="@/assets/Auth/basketShadowAuthSection.svg"
          alt="Basket Shadow"
        />
      </div>
    </div>
  </v-main>
</template>

<script>
import authToken from "@/common/authToken";
// import { ROUTER_URL } from "@/constants/urls";
import Helper from "@/helper";
export default {
  name: "AuthLayout",
  components: {},
  data() {
    return {};
  },
  created() {},
  computed: {
    getImgSrc() {
      console.log("login", "");
      if (this.$route.name === "forgotPassword")
        return require("@/assets/Auth/authLayout.svg");
      else if (this.$route.name === "resetPassword")
        return require("@/assets/Auth/authLayout.svg");
      else return require("@/assets/Auth/authLayout.svg");
    },
  },
  watch: {},
  methods: {},
  mounted() {
    let userType = authToken.decodedToken().user_type;
    if (
      authToken.isCorrectAccessToken &&
      authToken.loginForFirstTime() === true
    ) {
      // Redirect to admin panel
      this.$router.push(Helper.getUserPanelUrl(userType));
    }
  },
};
</script>

<style scoped>
/* Base Styles */
.auth-container {
  display: flex;
  width: 100%;
  height: 100vh;
}

/* Left Side - Image */
.auth-left {
  width: 60vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(44 25 99);
}

.auth-image {
  max-width: 100%;
  height: auto;
  max-height: 100vh;
}

/* Right Side - Form */
.auth-right {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f7f7f7;
  position: relative;
  padding: 20px;
}

.auth-content {
  width: 80%;
  max-width: 400px;
  text-align: center;
}

.logo {
  width: 120px;
  height: auto;
  margin-bottom: 20px;
}

/* Basket Shadow Image */
.basket-shadow {
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: 60%;
}

/* 📌 Mobile Responsive */
@media screen and (max-width: 768px) {
  .auth-container {
    flex-direction: column;
    align-items: center;
  }

  .auth-left,
  .auth-right {
    width: 100%;
  }

  .auth-image {
    max-height: 50vh;
  }

  .basket-shadow {
    max-width: 80%;
  }
}

/* 📌 Tablet and Smaller Laptops */
@media screen and (max-width: 992px) {
  .auth-right {
    padding: 10px;
  }

  .auth-content {
    width: 90%;
  }

  .basket-shadow {
    max-width: 50%;
  }
}

/* 📌 Large Screens */
@media screen and (min-width: 1200px) {
  .auth-image {
    max-height: 100vh;
  }
}
</style>
